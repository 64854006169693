import { styled } from "styled-components";
import CardContainer from "../../../components/cards/CardContainer";
import { Plus } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { CollectionApiClient } from "../../../api/clients/collections-api-client";

const Button = styled.button`
  border: none;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
`;

const AddCollectionCard: React.FC = () => {
  const navigate = useNavigate();

  const addCollection = async () => {
    try {
      const collection = await CollectionApiClient.create({
        kind: "collection",
        is_explorable: false,
        created_at: Date.now(),
        metadata: {
          title: {
            en: "Untitled",
          },
        },
        analytics: {
          views: 0,
          downloads: 0,
          conversion: 0,
        },
      });

      navigate(`/collections/${collection.id}`);
    } catch (error) {
      console.error("Error creating collection:", error);
    }
  };

  return (
    <CardContainer as={Button} onClick={addCollection}>
      <Plus size={28} />
    </CardContainer>
  );
};

export default AddCollectionCard;
